<template>
  <div id="component-bigmsg">
    <transition :name="getTransitionName(1)" v-on:after-leave="bigMsgLeft('start')">
      <div v-show="bigMessage === 'start'" v-bind:style="styleObject" class="startinfo">
        START
      </div>   
    </transition>     
    <transition :name="getTransitionName(1)" v-on:after-leave="bigMsgLeft('finish')">
      <div v-show="bigMessage === 'finish'" v-bind:style="styleObject" class="startinfo">
        FINISH
      </div>   
    </transition>  
  </div>
</template>

<script>
export default {
  props: ['settings', 'receiveBigmessage', 'styleObject', 'animationsUsing', 'lastAnimationUsing', 'COLORS'],
  data() { return {
    bigMsgUnderlayShow: false,    // nicht einheitlich
    bigMessage: '', 
    nextBigMsg: '',
    
    VARIATION: [
        {
            index: 0,
            type: 'SYMBOL',
            desc: 'Symbols',
            difficulty: 'easy' // not active
        },
        {
            index: 1,
            type: 'ANIMATION',
            desc: 'Animations',
            difficulty: 'easy' // not active
        },
        {
            index: 2,
            type: 'ANIMATION_SYMBOL',
            desc: 'Animations-Symbols',
            difficulty: 'middle' // not active
        },
        {
            index: 3,
            type: 'COMPLEMENTARY_NUMBER',
            desc: 'Complementary_Numbers',
            difficulty: 'middle' // not active
        },        
        {
            index: 4,
            type: 'COMPLEMENTARY_NUMBERS_ADVANCED',
            desc: 'Complementary_Numbers_Advanced',
            difficulty: 'middle' // not active
        }
    ], 
  }}, 
  methods: {
    // Debug method
    clog(x) {       
        console.log(x);
    },
    //------------------------------
    bigMsgLeft() {
      if (this.nextBigMsg !== '') {
        this.setBigMsg(this.nextBigMsg);
        this.nextBigMsg = '';
      }
      this.bigMsgUnderlayShow = false;
    },
    setBigMsg: function(msg) {
        if (msg === '') {
            this.bigMessage = '';
        } 

        else if (msg === 'start' && this.bigMessage === '') {
            this.bigMessage = 'start';
        }
        else if (msg === 'start' && this.bigMessage !== '') {
            this.setBigMsg('');
            this.nextBigMsg = 'start';
        }

        else if (msg === 'finish' && this.bigMessage === '') {
            this.bigMessage = 'finish';
        }
        else if (msg === 'finish' && this.bigMessage !== '') {
            this.setBigMsg('');
            this.nextBigMsg = 'finish';
        }
    },
    getTransitionName: function(squareId) {
        return (this.settings.chosenGametype === this.VARIATION[0].index ||
                this.settings.chosenGametype === this.VARIATION[3].index ||
                this.settings.chosenGametype === this.VARIATION[4].index) ?
            'fade' :
            (
                this.animationsUsing.length !== 0 ? 
                this.animationsUsing[(squareId - 1)] : 
                (
                    this.lastAnimationUsing !== '' ?
                    this.lastAnimationUsing :
                    'fade'
                )
            );
    },
  },  // methods end
  computed: {
  
  },
  watch: {
    receiveBigmessage(msg) {
      this.setBigMsg(msg);
    }
  }

}
</script>

<style scoped>
#component-bigmsg .startinfo {
  position: relative;
  top: 200px;
  /*left: 50%;*/
  text-align: center;
  font-size: 200px;
  color: red;
  z-index: 3;
}
</style>