<template>
  <div id="app" v-cloak>
    <progressBar :progressbarStyleobject="progbarStyleObject" />
    <buttonBar
      :styleObject="styleObject"
      :VARIATION="VARIATION"
      :filterBrightness="forwardingfilterBrightness"
      @doRepeatGame="doRepeatGame"
      @doChangeGametype="doChangeGametype"
    />
    <gameBoard
      @callBigMessage="forwardBigMessage"
      :settings="settings"
      :styleObject="styleObject"
      :newroundInfo="arrangeNewround"
      :gametypeInfo="arrangeGametypeChange"
      @populateAnimationsUsing="animationsUsingReceived"
      @populateLastAnimationUsing="lastAnimationUsingReceived"
      @populateStyleObject="styleObjectReceived"
      @populateProgressbarStyleObject="progbarStyleObjReceived"
      @populateInfotext="infotextReceived"
      @populateInfotextShow="infotextShowReceived"
      @populateFilterBrightness="filterBrightnessReceived"
      :COLORS="COLORS"
    />
    <bigMessages
      :settings="settings"
      :receiveBigmessage="forwardingBigMessage"
      :styleObject="styleObject"
      :animationsUsing="sendingAnimationsUsing"
      :lastAnimationUsing="sendingLastAnimationUsing"
      :COLORS="COLORS"
    />
    <infotextBar
      :settings="settings"
      :infoText="forwardingInfotext"
      :infoTextShow="forwardingInfotextShow"
      :styleObject="styleObject"
      :animationsUsing="sendingAnimationsUsing"
      :lastAnimationUsing="sendingLastAnimationUsing"
    />
    <infoFooter :version="version" />
    <statistics @populateStat="statReceived" />

    <!-- DEBUG button -->
    <!-- <button v-bind:style="{ backgroundColor: backi, position: 'absolute', top: '10px' }" @click="doRepeatGame">deb New</button> -->
  </div>
</template>

<script>
import ButtonBar from "./components/ButtonBar.vue";
import Board from "./components/GameMatrix.vue";
import BigMessages from "./components/BigMessages.vue";
import InfotextBar from "./components/InfotextBar.vue";
import InfoFooter from "./components/InfoFooter.vue";
import ProgressBar from "./components/ProgressBar.vue";
import Statistics from "./components/Statistics.vue";

export default {
  name: "App",
  components: {
    buttonBar: ButtonBar,
    gameBoard: Board,
    bigMessages: BigMessages,
    infotextBar: InfotextBar,
    infoFooter: InfoFooter,
    progressBar: ProgressBar,
    statistics: Statistics,
  },
  data() {
    return {
      backi: "#346587", // debug
      forwardingBigMessage: " ",
      arrangeNewround: "doNew",
      arrangeGametypeChange: "",
      sendingAnimationsUsing: [],
      sendingLastAnimationUsing: "",
      VARIATION: [
        {
          index: 0,
          type: "SYMBOL",
          desc: "Symbols",
          difficulty: "easy", // not active
        },
        {
          index: 1,
          type: "ANIMATION",
          desc: "Animations",
          difficulty: "easy", // not active
        },
        {
          index: 2,
          type: "ANIMATION_SYMBOL",
          desc: "Animations-Symbols",
          difficulty: "middle", // not active
        },
        {
          index: 3,
          type: "COMPLEMENTARY_NUMBER",
          desc: "Complementary_Numbers",
          difficulty: "middle", // not active
        },
        {
          index: 4,
          type: "COMPLEMENTARY_NUMBERS_ADVANCED",
          desc: "Complementary_Numbers_Advanced",
          difficulty: "middle", // not active
        },
      ],
      COLORS: [
        // bright colors
        "#ff6347",
        "#008b8b",
        "#4cf72a",
        "#f26d2b",
        "#556b2f",
        "#918151",
        // dark colors
        "#ffa500",
        "#370A4A",
        "#125108",
        "#101055",
        "#135043",
      ],

      styleObject: {},
      progbarStyleObject: {},
      forwardingInfotext: ["", "", "", ""],
      forwardingInfotextShow: [false, false, false, false],
      forwardingfilterBrightness: "",

      // ***** settings start
      settings: {
        chosenGametype: 0,
      },
      // ***** settings end
      version: "3.1b",
    };
  },
  methods: {
    animationsUsingReceived: function (array) {
      this.sendingAnimationsUsing = array;
    },
    lastAnimationUsingReceived: function (text) {
      this.sendingLastAnimationUsing = text;
    },
    styleObjectReceived: function (obj) {
      this.styleObject = obj;
    },
    progbarStyleObjReceived: function (obj) {
      this.progbarStyleObject = obj;
    },
    infotextReceived: function (text) {
      this.forwardingInfotext = text;
    },
    infotextShowReceived: function (text) {
      this.forwardingInfotextShow = text;
    },
    filterBrightnessReceived: function (col) {
      this.forwardingfilterBrightness = col;
    },
    statReceived: function (statObject) {
      //debug
      console.log(statObject);
    },

    forwardBigMessage: function (msg) {
      this.forwardingBigMessage = msg;
    },

    doRepeatGame: function () {
      this.arrangeNewround = this.arrangeNewround + ".";
    },
    doChangeGametype: function (x) {
      this.arrangeGametypeChange = x;
    },
  },
  created() {},
};
</script>

<style scoped>
[v-cloak] {
  display: none;
}

#app {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>