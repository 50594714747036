var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"btn-group"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"btn btn-group-sm btn-error rounded",style:({
        color: _vm.styleObject.color,
        borderColor: _vm.styleObject.borderColor,
        backgroundColor: _vm.styleObject.backgroundColor,
      }),attrs:{"type":"button","id":"addLine","disabled":false},on:{"click":_vm.debugFinishGame}},[_c('span',{staticClass:"glyphicon glyphicon-plus"}),_vm._v(" "),_c('i',[_vm._v("Debug end")])]),_c('button',{staticClass:"btn btn-group-sm btn-text-nowrap btn-info rounded",style:({
        color: _vm.styleObject.color,
        borderColor: _vm.styleObject.borderColor,
        backgroundColor: _vm.styleObject.backgroundColor,
      }),attrs:{"type":"button","id":"addLine","disabled":_vm.gameBtnsDisabled},on:{"click":_vm.callRepeatGame}},[_c('span',{staticClass:"glyphicon glyphicon-plus"}),_vm._v(" New Game ")]),_c('div',{staticClass:"btn-group-inner"},[_c('button',{staticClass:"btn btn-group-sm btn-text-nowrap btn-info dropdown-toggle rounded",style:(_vm.styleObject),attrs:{"type":"button","data-toggle":"dropdown","disabled":_vm.gameBtnsDisabled}},[_c('span',{staticClass:"glyphicon glyphicon-th"}),_vm._v(" Game type ")]),_c('ul',{staticClass:"dropdown-menu cursor-pointer",attrs:{"role":"menu"},on:{"click":_vm.callChangeGametype}},[_c('li',{staticClass:"menuitem-hover",attrs:{"value":_vm.VARIATION[0].index}},[_vm._v("Symbols")]),_c('li',{staticClass:"menuitem-hover",attrs:{"value":_vm.VARIATION[1].index}},[_vm._v("Animations")]),_c('li',{staticClass:"menuitem-hover",attrs:{"value":_vm.VARIATION[2].index}},[_vm._v(" Animations-Symbols ")]),_c('li',{staticClass:"menuitem-hover",attrs:{"value":_vm.VARIATION[3].index}},[_vm._v(" Compl-Numbers ")]),_c('li',{staticClass:"menuitem-hover",attrs:{"value":_vm.VARIATION[4].index}},[_vm._v(" Compl-Numbers-Adv ")]),_c('li',{staticClass:"menuitem-hover",attrs:{"value":_vm.VARIATION[4].index}},[_vm._v(" Compl-test 111 ")]),_c('li',{staticClass:"menuitem-hover",attrs:{"value":_vm.VARIATION[4].index}},[_vm._v(" Compl-test 22 ")]),_c('li',{staticClass:"menuitem-hover",attrs:{"value":_vm.VARIATION[4].index}},[_vm._v(" Compl-test 444 ")]),_c('li',{staticClass:"menuitem-hover",attrs:{"value":_vm.VARIATION[4].index}},[_vm._v(" Compl-test55555 ")])])]),_c('div',{staticClass:"flow-right-border"},[_c('button',{staticClass:"btn btn-group-sm btn-info rounded",style:({
          color: _vm.styleObject.color,
          borderColor: _vm.styleObject.borderColor,
          backgroundColor: _vm.styleObject.backgroundColor,
        }),attrs:{"type":"button","disabled":_vm.pauseBtnDisabled},on:{"click":_vm.callPause}},[_vm._v(" "+_vm._s(_vm.pauseText)+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }