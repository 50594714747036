<template>
  <div id="component-matrix">
    <div class="game-board-container">
      <div
        v-bind:style="{ backgroundColor: styleObject.backgroundColor }"
        v-show="true"
        class="board-overlay"
      ></div>
      <table>
        <tr v-for="rowCount in 4" v-bind:key="rowCount.id">
          <td
            v-for="colCount in 4"
            v-bind:key="colCount.id"
            v-on:click="showElement"
            class="cell"
            v-bind:style="{ borderColor: styleObject.mainColor }"
            :id="rowCount * 4 - 4 + colCount"
          >
            <div>
              <transition
                :name="getTransitionName(rowCount * 4 - 4 + colCount)"
              >
                <span
                  v-bind:style="{ color: styleObject.mainColor }"
                  v-show="elementsShow[rowCount * 4 - 4 + colCount - 1]"
                ></span>
              </transition>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <!-- <div id="dialog" title="Basic dialog">
      <p>
        This is the default dialog which is useful for displaying information.
        The dialog window can be moved, resized and closed with the
        &apos;x&apos; icon.
      </p>
    </div> -->
  </div>
</template>

<script>
import { eventBus } from "../main";
// import $ from "jquery";

export default {
  props: ["settings", "newroundInfo", "COLORS", "gametypeInfo"],
  data() {
    return {
      // const start
      cells: document.querySelectorAll(".cell"),
      DISPLAY_TIMEOUT: 1000,
      VARIATION: [
        {
          index: 0,
          type: "SYMBOL",
          desc: "Symbols",
          difficulty: "easy", // not active
        },
        {
          index: 1,
          type: "ANIMATION",
          desc: "Animations",
          difficulty: "easy", // not active
        },
        {
          index: 2,
          type: "ANIMATION_SYMBOL",
          desc: "Animations-Symbols",
          difficulty: "middle", // not active
        },
        {
          index: 3,
          type: "COMPLEMENTARY_NUMBER",
          desc: "Complementary_Numbers",
          difficulty: "middle", // not active
        },
        {
          index: 4,
          type: "COMPLEMENTARY_NUMBERS_ADVANCED",
          desc: "Complementary_Numbers_Advanced",
          difficulty: "middle", // not active
        },
      ],
      SYMBOLS: ["O", "L", "X", "A", "S", "U", "W", "P"],
      ANIMATIONS: [
        "fade",
        "slide-fade",
        "slide-fade-second",
        "dropdown",
        "dropup",
        "alert-in",
        "alert-in-second",
        "blink",
      ],
      // the pair will be found through subtracting (100 - x)
      COMPLEMENTARY_NUMBERS: [5, 10, 15, 20, 25, 30, 35, 40],
      COMPLEMENTARY_NUMBERS_ADVANCED: [17, 14, 7, 21, 27, 38, 39, 44],
      ROOT_MATH: [
        ["√9", "3"],
        ["√16", "4"],
        ["√81", "9"],
        ["√49", "7"],
        ["√64", "8"],
        ["√25", "5"],
        ["√36", "6"],
        ["√4", "2"],
      ],
      // const end

      infoText: ["", "", "", ""],
      infoTextShow: [false, false, false, false],

      styleObject: {
        backgroundColor: "",
        color: "",
        borderColor: "",
        mainColor: "",
      },

      cellId: 0,
      lastSquareId: 0,
      currTargetValue: "",
      lastTargetValue: "",
      lastTargetAnimation: "",
      foundPairsCounter: 0,
      progressbarStyleobject: {
        height: "0%",
        backgroundColor: "green",
      },
      selectedCount: 0,

      currentSquareId: -1,
      bigMsgUnderlayShow: false, // nicht einheitlich
      bigMessage: "", // ???
      elementsShow: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      elementsShowCount: 0,
      deactivatedSquares: [],
      timeoutIDsElements: [],
      animationsUsing: [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      lastAnimationUsing: "",
      interactionsArray: [],
      interaction: {
        id: "",
        clickFirst: "",
        clickSecond: "",
        match: "",
      },
      changingGametypeTo: 3, // ****************************
    };
  },
  methods: {
    // DEBUG START --------------------------
    clog(x) {
      console.log(x);
    },
    // DEBUG END -----------------------------

    init: function () {
      this.cells = document.querySelectorAll(".cell");
      // EMitting data  // ??
      // this.$emit('methode', this.getTransitionName);
    },
    checkNonAnimationGame: function () {
      return (
        this.settings.chosenGametype === this.VARIATION[0].index ||
        this.settings.chosenGametype === this.VARIATION[3].index ||
        this.settings.chosenGametype === this.VARIATION[4].index
      );
    },
    getTransitionName: function (squareId) {
      return this.checkNonAnimationGame()
        ? "fade"
        : this.animationsUsing.length !== 0
        ? this.animationsUsing[squareId - 1]
        : this.lastAnimationUsing !== ""
        ? this.lastAnimationUsing
        : "fade";
    },

    updateProgressbar: function (value) {
      let self = this;
      setTimeout(function () {
        self.progressbarStyleobject.height = value + "%";
      }, 1000);
    },

    setElementShow: function (ind, setAll) {
      const self = this;
      let waitEffect = 0;

      if (!setAll) {
        this.$set(this.elementsShow, ind - 1, !this.elementsShow[ind - 1]);
        waitEffect = !this.cells[ind - 1].classList.contains("cell-hover")
          ? 1500
          : 0;
        setTimeout(function () {
          self.cells[ind - 1].classList.toggle("cell-hover");
        }, waitEffect);
      } else {
        for (let i = 0; i < this.elementsShow.length; i++) {
          this.$set(this.elementsShow, i, false);
        }
        [...this.cells].forEach((cell) => {
          cell.classList.remove("cell-hover");
        });
      }
    },
    showElement: function (square) {
      const self = this;
      // later nicer!
      let squareTdId = getIdofTdSquare(square.target),
        clickFirstSecondCounter = self.elementsShowCount % 2 !== 1 ? 1 : 2,
        blockingNumbers = [];
      function getIdofTdSquare(square) {
        let squareTd = square;
        while (squareTd.tagName !== "TD") {
          squareTd = squareTd.parentNode;
        }
        return parseInt(squareTd.id);
      }
      function setCurrentSquareId(squareId) {
        // make it later nicer with get and set of computed-section
        // (? but also be carefful with the computed section ?)
        self.currentSquareId = squareId;
      }
      function checkSecondClick() {
        return (
          clickFirstSecondCounter === 2 &&
          self.interaction.clickFirst !== squareTdId
        );
      }
      function clearInteractionObject() {
        self.interaction = {};
        self.interaction.id = "";
        self.interaction.clickFirst = "";
        self.interaction.clickSecond = "";
        self.interaction.match = "";
      }
      function getCurrentTargetValue() {
        return self.checkNonAnimationGame()
          ? document.getElementById(self.currentSquareId).firstChild.firstChild
              .innerText
          : self.animationsUsing[self.currentSquareId - 1];
      }
      function hideElements(element1, element2) {
        let timeoutID = setTimeout(function () {
          self.setElementShow(element1);
          self.setElementShow(element2);
        }, self.DISPLAY_TIMEOUT);
        self.timeoutIDsElements.push(timeoutID);
      }
      function nonComplementaryNumbers(self) {
        return (
          self.settings.chosenGametype !== self.VARIATION[3].index &&
          self.settings.chosenGametype !== self.VARIATION[4].index
        );
      }
      function getComparingElements(p_currTargetValue) {
        return nonComplementaryNumbers(self)
          ? p_currTargetValue === self.lastTargetValue
          : parseInt(p_currTargetValue) ===
              100 - parseInt(self.lastTargetValue) ||
              100 - parseInt(p_currTargetValue) ===
                parseInt(self.lastTargetValue);
      }
      function compareElements() {
        let currTargetValue;
        if (self.selectedCount >= 2) {
          currTargetValue = getCurrentTargetValue();
          if (getComparingElements(currTargetValue)) {
            self.foundPairsCounter++;
            self.updateProgressbar((100 / 8) * self.foundPairsCounter);
            self.deactivatedSquares.push(
              self.currentSquareId,
              self.lastSquareId
            );
          } else {
            hideElements(self.currentSquareId, self.lastSquareId);
          }
          self.interaction.match = currTargetValue === self.lastTargetValue;
          self.selectedCount = self.selectedCount - 2;
        } else if (self.selectedCount === 1) {
          self.lastSquareId = self.currentSquareId;
          self.lastTargetValue = getCurrentTargetValue();
        }
      }

      this.interactionsArray
        .filter((el) => el.match === true)
        .forEach((el) => {
          blockingNumbers.push(el.clickFirst);
          blockingNumbers.push(el.clickSecond);
        });
      if (blockingNumbers.indexOf(squareTdId) === -1) {
        if (clickFirstSecondCounter === 1) {
          clearInteractionObject();
          this.interaction.id = this.interactionsArray.length + 1;
          this.interaction.clickFirst = squareTdId;
        } else if (checkSecondClick()) {
          this.interaction.clickSecond = squareTdId;
          this.interactionsArray.push(this.interaction);
        }
        if (this.deactivatedSquares.indexOf(squareTdId) === -1) {
          setCurrentSquareId(squareTdId);
          this.setElementShow(squareTdId);
          if (this.elementsShow[squareTdId - 1]) {
            this.selectedCount++;
            // count added   // DEBUG
            this.debelementsAdded++;
          } else {
            this.selectedCount--;
            // count removed   // DEBUG
            this.debelementsSubstr++;
          }
          compareElements();
          // TODO count clickOpened square
          this.elementsOpen++;
        }
      }
      // TODO commonCLicks on board
      this.commonClicks++;
    },
    finishGame: function () {
      const self = this;
      eventBus.$emit("finishGameStatistics", {
        pairsOpenend: this.interactionsArray.length,
        squaresOpened: this.elementsOpen,
        commonClicks: this.commonClicks,
      });
      setTimeout(function () {
        self.$emit("callBigMessage", "finish");
        eventBus.$emit("showStat");
        eventBus.$emit("updateButtons", "finish");
      }, this.DISPLAY_TIMEOUT);
    },
    setBigMsg(msg) {
      this.$emit("callBigMessage", msg);
    },
    setInfoText: function (msg, index) {
      // 1 disappearing
      if (this.infoText[index] !== "" && msg === "") {
        // disappearing
        this.$set(this.infoTextShow, index, false);
        // deleting msg happens in the animation-left event
      }
      // 3 appearing
      else if (this.infoText[index] === "" && msg !== "") {
        // appearing
        this.$set(this.infoText, index, msg);
        this.$set(this.infoTextShow, index, true);
      }
      this.$emit("populateInfotext", this.infoText);
      this.$emit("populateInfotextShow", this.infoTextShow);
    },
    prepareNewGame() {
      // before I had here parameter 'self'; so I shifted it now to own declarating insides
      const self = this;

      function calculateTextcolor(color) {
        let rgb = parseInt(color.substring(1), 16), // convert rrggbb to decimal
          red = (rgb >> 16) & 0xff,
          green = (rgb >> 8) & 0xff,
          blue = (rgb >> 0) & 0xff,
          luma;

        luma = 0.299 * red + 0.587 * green + 0.114 * blue;
        return luma > 140 ? "#000000" : "#ffffff";
      }
      function isTextcolorBlack(color) {
        return calculateTextcolor(color) == "#000000";
      }
      function setNewColor(color, self) {
        // const
        //     buttons = document.querySelectorAll('.btn'),
        const filterBrightness = isTextcolorBlack(color) ? "0.6" : "2.0";

        self.styleObject.backgroundColor = color;
        self.styleObject.borderColor = color;
        self.styleObject.mainColor = color;
        self.styleObject.color = calculateTextcolor(color);
        self.$emit("populateStyleObject", self.styleObject);

        self.progressbarStyleobject.backgroundColor = color;
        self.$emit(
          "populateProgressbarStyleObject",
          self.progressbarStyleobject
        );
        [...document.querySelectorAll(".menuitem-hover")].forEach((item) => {
          // later copy to menu component ***********8
          item.style.setProperty("--li-background-color", color); // thats for buttons hover effect
          item.style.setProperty("--li-color", calculateTextcolor(color));
        });
        [...self.cells].forEach((cell) => {
          cell.classList.add("cell-hover");
          cell.style.setProperty("--td-hover-color", color);
        });
        self.$emit("populateFilterBrightness", filterBrightness);
      }

      function getRandomColor() {
        let num = Math.floor(Math.random() * self.COLORS.length);
        return self.COLORS[num];
      }

      function prepareGameColor(self) {
        setNewColor(getRandomColor(), self);
      }

      // placeholder   'prepareComplNumbers'

      function startGame(self) {
        let symbolsList = [...self.availableSymbolsList];
        let animationList;
        let complementaryNumbersList = [...self.availableComplNumbersList];
        let complementaryNumbersAdvList = [
          ...self.availableComplNumbersAdvList,
        ];
        let randomNumber = Math.floor(Math.random() * self.SYMBOLS.length);
        let numsAnim = [];
        let newNumsAnim = [];
        let gameType = self.settings.chosenGametype;

        // is there a possibility to run if outside with vars-declare; and only one FOR where vars will be used
        for (let i = 0; i < self.cells.length; i++) {
          if (gameType === self.VARIATION[0].index) {
            // symbol
            self.cells[i].firstChild.firstChild.innerText = symbolsList.splice(
              // do that as a function here !!!
              Math.random() * symbolsList.length,
              1
            );
          } else if (gameType === self.VARIATION[1].index) {
            // animation
            self.cells[i].firstChild.firstChild.innerText =
              self.SYMBOLS[randomNumber];
            animationList = self.availableAnimationsList.sort(
              () => 0.5 - Math.random()
            );
            self.animationsUsing = [...animationList];
          } else if (gameType === self.VARIATION[2].index) {
            // animation_symbol
            for (let i = 0; i < 8; i++) {
              numsAnim.push(i, i);
            }
            newNumsAnim = numsAnim.sort(() => 0.5 - Math.random());
            self.cells[i].firstChild.firstChild.innerText =
              self.SYMBOLS[newNumsAnim.splice(0, 1)];
            animationList = self.availableAnimationsList.sort(
              () => 0.5 - Math.random()
            );
            self.animationsUsing = [...animationList];
          } else if (gameType === self.VARIATION[3].index) {
            // complementary_numbers
            // prepareComplNumbers(); LATER outsource
            self.cells[i].firstChild.firstChild.innerText =
              complementaryNumbersList.splice(
                Math.random() * complementaryNumbersList.length,
                1
              );
          } else if (gameType === self.VARIATION[4].index) {
            // complementary_numbers_advanced
            // prepareComplNumbers(); LATER outsource
            self.cells[i].firstChild.firstChild.innerText =
              complementaryNumbersAdvList.splice(
                Math.random() * complementaryNumbersAdvList.length,
                1
              );
          }
        }
        self.setInfoText(self.getGametypeDesc, 0);
        self.setInfoText("", 3);
        self.setBigMsg("");
        eventBus.$emit("updateButtons", "start");
      }

      // this.bigMsgUnderlayShow = true;
      this.setBigMsg("start");
      prepareGameColor(self);
      // todo send startProcedure of stats event
      setTimeout(() => {
        self.settings.chosenGametype = self.changingGametypeTo;
        // this.setInfoText(this.getGametypeDesc, 0); // anf
        // call theres
        startGame(self);
        eventBus.$emit("startGameStatistics");
      }, this.DISPLAY_TIMEOUT + 1500);
    },

    repeatGame: function () {
      this.deactivatedSquares.push.apply(
        this.deactivatedSquares,
        Array.from(Array(16).keys())
      );
      this.timeoutIDsElements.forEach((num) => {
        clearTimeout(num);
      });
      this.timeoutIDsElements.splice(0);

      // let disappear all elements
      this.setElementShow("", true);
      this.setInfoText("", 0); // do6
      this.updateProgressbar(0);

      this.deactivatedSquares.splice(0);
      this.lastAnimationUsing = this.animationsUsing[0];
      this.animationsUsing.splice(0);
      this.interactionsArray.splice(0);
      this.elementsShowCount = 0;
      this.foundPairsCounter = 0;
      this.selectedCount = 0; // that name could be imporeved: not exactly enouth
      this.elementsOpen = 0;
      this.debelementsAdded = 0; // DEBUG  check a
      this.debelementsSubstr = 0; // DEBUG  check b
      this.commonClicks = 0;
      this.prepareNewGame();
    },
    changeGametype: function (gameType) {
      if (gameType !== this.settings.chosenGametype) {
        this.changingGametypeTo = gameType;
        this.setInfoText("game type changing next round", 3); // textfeld 4
      }
    },
  }, // methods end
  computed: {
    getGametypeDesc: function () {
      let returnValue = "";
      switch (this.settings.chosenGametype) {
        case 0:
          returnValue = "Symbols";
          break;
        case 1:
          returnValue = "Animations";
          break;
        case 2:
          returnValue = "Animations_Symbols";
          break;
        case 3:
          returnValue = "Complementary_Numbers";
          break;
        case 4:
          returnValue = "Complementary_Numbers_Advanced";
          break;
        default:
          returnValue = "<unkown>";
      }
      return returnValue;
    },
    availableSymbolsList: function () {
      let newArray = [];
      for (let i = 0; i < this.SYMBOLS.length; i++) {
        newArray.push(this.SYMBOLS[i], this.SYMBOLS[i]);
      }
      return newArray;
    },
    availableAnimationsList: function () {
      let newArray = [];
      for (let i = 0; i < this.ANIMATIONS.length; i++) {
        newArray.push(this.ANIMATIONS[i], this.ANIMATIONS[i]);
      }
      return newArray;
    },
    availableComplNumbersList: function () {
      let newArray = [];
      for (let i = 0; i < this.COMPLEMENTARY_NUMBERS.length; i++) {
        newArray.push(
          this.COMPLEMENTARY_NUMBERS[i],
          100 - this.COMPLEMENTARY_NUMBERS[i]
        );
      }
      return newArray;
    },
    availableComplNumbersAdvList: function () {
      let newArray = [];
      for (let i = 0; i < this.COMPLEMENTARY_NUMBERS_ADVANCED.length; i++) {
        newArray.push(
          this.COMPLEMENTARY_NUMBERS_ADVANCED[i],
          100 - this.COMPLEMENTARY_NUMBERS_ADVANCED[i]
        );
      }
      return newArray;
    },
  },
  watch: {
    foundPairsCounter(counter) {
      counter >= 8 ? this.finishGame() : "";
    },
    elementsShow(self) {
      this.elementsShowCount = self.filter(
        (element) => element === true
      ).length;
    },
    newroundInfo() {
      // by now realised by adding a dot always
      this.repeatGame();
    },
    gametypeInfo(gameType) {
      this.changeGametype(gameType);
    },
    animationsUsing() {
      this.$emit("populateAnimationsUsing", this.animationsUsing);
    },
    lastAnimationUsing() {
      this.$emit("populateLastAnimationUsing", this.lastAnimationUsing);
    },
    styleObject() {
      // console.log('styleObject updated');
      this.$emit("populateStyleObject", this.styleObject);
    },
  },
  mounted() {
    this.init();
    this.prepareNewGame(this);
  },
  created() {
    eventBus.$on("doDebugFinishGame", () => {
      this.foundPairsCounter += 8;
    });
    eventBus.$on("doPauseGame", () => {
      const boardOverlay = document.querySelector(".board-overlay"),
        visibilityStatus =
          boardOverlay.style.getPropertyValue("visibility") || "hidden";
      boardOverlay.style.setProperty(
        "visibility",
        visibilityStatus === "hidden" ? "visible" : "hidden"
      );
      eventBus.$emit("pauseGameStatistics");
    });
    // $(function () {
    //   $("#dialog").dialog();
    // });
  },
};
</script>

<style scoped>
#component-matrix table {
  border-collapse: collapse;
  position: absolute;
  top: 50px;
  left: 50%;
  margin-left: -300px;
  margin-top: 30px;
}

.game-board-container {
  position: relative;
}

.board-overlay {
  position: absolute;
  visibility: hidden;
  z-index: 3;

  width: 600px;
  height: 600px;
  top: 50px;
  left: 50%;
  margin-left: -300px;
  margin-top: 30px;

  opacity: 0.65;
  background: "";
  box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(150px);
}

td {
  border: 2px solid #5bc0de /*#333*/;
  height: 150px;
  width: 150px;
  text-align: center;
  vertical-align: middle;
  font-family: "Comic Sans MS", cursive;
  font-size: 100px;
  cursor: pointer;
}

#component-matrix table tr:first-child td {
  border-top: 0;
}
#component-matrix table tr:last-child td {
  border-bottom: 0;
}
#component-matrix table tr td:first-child {
  border-left: 0;
}
#component-matrix table tr td:last-child {
  border-right: 0;
}

table tr td div span {
  display: inline-block;
}
</style>

<style>
/* ################### Animations - Start ################### 
/* fade  */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.5s;
}
.fade-enter
,.fade-leave-to 
/*
.fade-leave-active 'below version 2.1.8' */ {
  opacity: 0;
}
/* ===================== */

/* blink  */
.blink-enter-active,
.blink-leave-active {
  animation: blink 0.5s infinite;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* ===================== */

/* slide-fade */
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(30px);
  opacity: 0;
}
/* ===================== */

/* slide-fade-second */
.slide-fade-second-enter-active {
  transition: all 1.5s ease;
}
.slide-fade-second-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-second-enter,
.slide-fade-second-leave-to {
  transform: translateX(-50px);
  /*transform:  translateY(-50px);*/
  opacity: 0;
}
/* ===================== */

/* dropdown */
.dropdown-enter,
.dropdown-leave-to {
  transform: scaleY(0.1);
  opacity: 0;
}

.dropdown-enter-to,
.dropdown-leave {
  opacity: 1;
  transform: scaleY(1);
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 1s ease-out;
  transform-origin: top center;
}
/* ===================== */

/* dropup */
.dropup-enter,
.dropup-leave-to {
  transform: scaleY(0.1);
  opacity: 0;
}

.dropup-enter-to,
.dropup-leave {
  opacity: 1;
  transform: scaleY(1);
}

.dropup-enter-active,
.dropup-leave-active {
  transition: all 1s ease-in;
  transform-origin: center bottom;
}
/* ===================== */

/* alert-in  */
.alert-in-enter-active {
  animation: bounce-in 0.5s;
}
.alert-in-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
/* ===================== */

/* alert-in-second */
.alert-in-second-enter-active {
  animation: bounce-in 0.5s;
  animation-name: bounce-in-second;
}
.alert-in-second-leave-active {
  animation: bounce-in 0.5s reverse;
  animation-name: bounce-in-second;
}
@keyframes bounce-in-second {
  0% {
    transform: scale(3);
  }
  12% {
    transform: scale(2.5);
  }
  25% {
    transform: scale(2);
  }
  37% {
    transform: scale(1.75);
  }
  50% {
    transform: scale(1.5);
  }
  62% {
    transform: scale(2);
  }
  75% {
    transform: scale(2.5);
  }
  87% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
/* ===================== */
/* ################### Animations - End ################### */
</style>