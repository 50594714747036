<template>
  <div class="progress-box">        
    <div class="progress progress-bar-vertical">
      <div v-bind:style="progressbarStyleobject" class="progress-bar progress-striped" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"> </div>
    </div>
  </div>
</template>


<script>
export default {
  props: ['progressbarStyleobject']
}
</script>


<style scoped>
/* ##### progress bar ##### */
.progress-box {
  display: inline-block;
  
  position: absolute;

  left: 50%;
  top: 30px;

  margin-top: 30px;
  margin-left: -350px;
}

.progress-bar-vertical {
    width: 10px;
    min-height: 600px;
    margin-right: 20px;

    background: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-end;
    -webkit-align-items: flex-end
}

.progress-bar-vertical .progress-bar {
    width: 100%;
    /*height: 10%;*/  /*would go*/
    background-color: purple;
    -webkit-transition: height 0.6s ease;
    -o-transition: height 0.6s ease;
    transition: height 0.6s ease
}
</style>