import Vue from "vue";
import App from "./App.vue";

import BootstrapVue from "bootstrap-vue";
// import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
// import bootstrapCss from "bootstrap-css (remove in 3_1b version)";

export const eventBus = new Vue();

Vue.use(BootstrapVue);
// Vue.use(bootstrapCss);  // -->> bekomme es ohne dem hier Hin
// Vue.use(IconsPlugin);

// Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  render: (h) => h(App),
}).$mount("#app");
