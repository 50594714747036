<template>
  <div id="app">
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-group-sm btn-error rounded"
        id="addLine"
        v-bind:style="{
          color: styleObject.color,
          borderColor: styleObject.borderColor,
          backgroundColor: styleObject.backgroundColor,
        }"
        v-on:click="debugFinishGame"
        v-show="false"
        :disabled="false"
      >
        <span class="glyphicon glyphicon-plus"></span> <i>Debug end</i>
      </button>
      <button
        type="button"
        class="btn btn-group-sm btn-text-nowrap btn-info rounded"
        id="addLine"
        v-bind:style="{
          color: styleObject.color,
          borderColor: styleObject.borderColor,
          backgroundColor: styleObject.backgroundColor,
        }"
        v-on:click="callRepeatGame"
        :disabled="gameBtnsDisabled"
        >
        <span class="glyphicon glyphicon-plus"></span> New Game
      </button>
      <div class="btn-group-inner">
        <button
          type="button"
          class="btn btn-group-sm btn-text-nowrap btn-info dropdown-toggle rounded"
          v-bind:style="styleObject"
          data-toggle="dropdown"
          :disabled="gameBtnsDisabled"
          >
          <span class="glyphicon glyphicon-th"></span> Game type
        </button>
        <ul
          class="dropdown-menu cursor-pointer"
          role="menu"
          v-on:click="callChangeGametype"
        >
          <li class="menuitem-hover" :value="VARIATION[0].index">Symbols</li>
          <li class="menuitem-hover" :value="VARIATION[1].index">Animations</li>
          <li class="menuitem-hover" :value="VARIATION[2].index">
            Animations-Symbols
          </li>
          <li class="menuitem-hover" :value="VARIATION[3].index">
            Compl-Numbers
          </li>
          <li class="menuitem-hover" :value="VARIATION[4].index">
            Compl-Numbers-Adv
          </li>
          <li class="menuitem-hover" :value="VARIATION[4].index">
            Compl-test 111
          </li>
          <li class="menuitem-hover" :value="VARIATION[4].index">
            Compl-test 22
          </li>
          <li class="menuitem-hover" :value="VARIATION[4].index">
            Compl-test 444
          </li>
          <li class="menuitem-hover" :value="VARIATION[4].index">
            Compl-test55555
          </li>
        </ul>
      </div>

      <div class="flow-right-border">
        <button
          type="button"
          class="btn btn-group-sm btn-info rounded"
          v-bind:style="{
            color: styleObject.color,
            borderColor: styleObject.borderColor,
            backgroundColor: styleObject.backgroundColor,
          }"
          v-on:click="callPause"
          :disabled="pauseBtnDisabled"
          >
          {{ pauseText }}
        </button>
        <!-- <input type="button" class="btn-right" value="Pause" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { eventBus } from "../main";

export default {
  props: ["styleObject", "VARIATION", "filterBrightness"],
  data() {
    return {
      gameBtnsDisabled: false,
      pauseBtnDisabled: false,
    };
  },
  methods: {
    callRepeatGame: function () {
      this.$emit("doRepeatGame");
    },
    callChangeGametype: function (el) {
      this.$emit("doChangeGametype", parseInt(el.target.getAttribute("value")));
    },
    callPause: function () {
      eventBus.$emit("doPauseGame");
      this.gameBtnsDisabled = !this.gameBtnsDisabled;
    },
    setNewColor: function () {
      const buttons = document.querySelectorAll(".btn");

      console.log("##btn-color-problem-seek: doch hier etwas nicht");
      [...buttons].forEach((btn) => {
        btn.classList.add("btn-hover");
        btn.style.setProperty(
          "background-color",
          this.styleObject.backgroundColor
        );
        btn.style.setProperty("--filter-brightness", this.filterBrightness);
      });
    },
    // DEBUG methods debugFinishGame
    debugFinishGame: function () {
      eventBus.$emit("doDebugFinishGame");
    },
  },
  watch: {
    filterBrightness: function () {
      this.setNewColor();
    },
  },
  computed: {
    pauseText() {
      return this.gameBtnsDisabled ? "Continue" : "Pause";
    },
  },
  created() {
    eventBus.$on("updateButtons", (statusGame) => {
      this.pauseBtnDisabled = statusGame === "finish";
    });
  },
};
</script>

<style>
.flow-right-border {
  /* background-color: orange; */

  width: 100%;
  text-align: right;
}
p.aham {
  margin-bottom: 10px;
}

.btn-text-nowrap {
  /* border-radius: 4px; (remove in 3_1b version)*/ 
  white-space: nowrap;
}
.btn-group {
  /* display: inline-block; (remove in 3_1b version)*/
  position: absolute;

  margin-top: 20px;
  margin-left: -300px;
  /* border: 1px white; (remove in 3_1b version)*/
  /* border: 1px solid black; (remove in 3_1b version)*/

  left: 50%;
  width: 600px;
}
.btn-group-inner {
  position: relative;
  display: inline-block;
}
.btn-group > button {
  margin-right: 5px;
}
div > button.btn.btn-info.btn-hover:hover {
  filter: brightness(var(--filter-brightness));
}

.cursor-pointer {
  cursor: pointer;
}
li.menuitem-hover:hover {
  background-color: red;
  background-color: var(--li-background-color);
  color: var(--li-color);
  opacity: 80%;
}
</style>