<template>
  <div id="component-infotext">
    <div v-bind:style="styleObject" class="btn-group btn-group-sm endgame">
      <div>
        <span v-for="msg in infoText" :key=msg.key>
          <transition :name="getTransitionName(1)" v-on:after-leave="infotextLeft(infoText.indexOf(msg))">
            <span v-show="infoTextShow[infoText.indexOf(msg)]">{{ msg }}</span>
          </transition>
        </span>      
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['settings' ,'infoText', 'infoTextShow', 'styleObject', 'animationsUsing', 'lastAnimationUsing'],
  data() { return { 
    VARIATION: [
        {
            index: 0,
            type: 'SYMBOL',
            desc: 'Symbols',
            difficulty: 'easy' // not active
        },
        {
            index: 1,
            type: 'ANIMATION',
            desc: 'Animations',
            difficulty: 'easy' // not active
        },
        {
            index: 2,
            type: 'ANIMATION_SYMBOL',
            desc: 'Animations-Symbols',
            difficulty: 'middle' // not active
        },
        {
            index: 3,
            type: 'COMPLEMENTARY_NUMBER',
            desc: 'Complementary_Numbers',
            difficulty: 'middle' // not active
        },        
        {
            index: 4,
            type: 'COMPLEMENTARY_NUMBERS_ADVANCED',
            desc: 'Complementary_Numbers_Advanced',
            difficulty: 'middle' // not active
        }
    ], 
  } },
  methods: {
    getTransitionName: function(squareId) {
      return (this.settings.chosenGametype === this.VARIATION[0].index ||
              this.settings.chosenGametype === this.VARIATION[3].index ||
              this.settings.chosenGametype === this.VARIATION[4].index) ?
        'fade' :
        (
          this.animationsUsing.length !== 0 ? 
          this.animationsUsing[(squareId - 1)] : 
          (
            this.lastAnimationUsing !== '' ?
            this.lastAnimationUsing :
            'fade'
          )
        );
    },    
    infotextLeft(index) {
        this.$set(this.infoText, index, '');
    },    
  },
  watch: {
  },
  computed: {}
}
</script>

<style scoped>
.btn-group.endgame {
  position: absolute;
  margin-top: 50px;
  /* margin-bottom: 40px; */
  top: 650px;
  /* border-width: 1px; */
  border: 3px solid #5bc0de;
  border-radius: 3px;
}

div > .btn-group {
  display: inline-block;
  position: absolute;

  margin-top: 20px;
  margin-left: -300px;
  border: 1px white;
  /*border: 1px solid black;*/

  left: 50%;
  width: 600px;
}

.endgame > div {
  height: 100px;
  text-align: center;
  vertical-align: middle;
}
.endgame > div > span {
  position: relative;
  display: inline-block;
  overflow-y: overflow;
  
  /*border: 1px white;*/
  /*margin-top: 20px;*/
  /*margin-down: 20px;*/
  /*border: 1px solid orange;*/
  top:  50%;
  margin-top: -50px;

  width: 145px;
  /*height: 50px;*/
  font-size: 20px;
  font-weight: bold;
}
.endgame > div > span:nth-child(4) {
  font-size: 14px;
}
</style>