<template>
  <div id="component-infofooter">
    <div class="additional-info-box">
      <div class="additional-info">
        <span>
          @Copyright by TeHaO | Version {{ version }} |
          <a
            href="javascript:void(0)"
            v-on:click="popup('./about.txt', 'About')"
            >About</a
          ></span
        >
        &nbsp;
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["version"],
  data() {
    return {};
  },
  methods: {
    popup: function (mylink, windowname) {
      let href;
      if (!window.focus) return true;
      if (typeof mylink == "string") {
        href = mylink;
      } else {
        href = mylink.href;
      }
      window.open(
        href,
        windowname,
        "width=400,height=300,scrollbars=no"
        // "width=400,height=300,scrollbars=no,toolbar=no,status=no,menu=no, directories=no,titlebar=no,location=no,addressbar=no"
      );
      return false;
    },
  },
};
</script>

<style scoped>
div.additional-info-box {
  margin-top: 20px;  
  display: block;
  position: absolute;
  top: 800px;
  left: 50%;
}
</style>