<template>
  <div class="component-stat"></div>
</template>


<script>
import _ from "lodash";
import { eventBus } from "../main";

// const faker = require("faker");
// tru faker: what happens hhere!??

export default {
  data() {
    return {
      statData: {
        playerName: "",
        startTime: 0,
        finishTime: 0,
        durationSec: 0,
        pairTries: 0,
        elementsOpened: 0,
        commonClicksOnBoard: 0,
        successRate: 0,
        // add game Type
      },
      gameStatisticsRecords: [],
      bestPlayerIndicies: {
        regardPairTries: 0,
        regardDuration: 0,
        regardEffectivity: 0,
      },
    };
  },
  methods: {
    // TODO -->populateStat, when it will be needed in main component to show  result
    saveAndUpdateStatistics: function (statRecord) {
      this.gameStatisticsRecords.push(statRecord);

      // ***** best player *****
      // 1. *** less pairsfoundtry
      this.gameStatisticsRecords.reduce((prevObj, currObj, ind) => {
        // debugger;   later      todo  todeb
        if (prevObj.pairTries === undefined) {
          console.log("empty NOW " + ind);
          return;
        }
        if (currObj.pairTries < prevObj.pairTries)
          this.bestPlayerIndicies.regardPairTries = ind;
      });

      // 2. *** fastest
      this.gameStatisticsRecords.reduce((prevObj, currObj, ind) => {
        console.log("prevObj");
        console.log(prevObj);
        if (prevObj !== undefined && prevObj !== null) {
          if (currObj.durationSec < prevObj.durationSec)
            this.bestPlayerIndicies.regardDuration = ind;
        }
      });

      // 3. *** most effective clicks
      this.gameStatisticsRecords.reduce((prevObj, currObj, ind) => {
        if (prevObj !== undefined && prevObj !== null) {
          if (currObj.successRate > prevObj.successRate)
            this.bestPlayerIndicies.regardEffectivity = ind;
        }
      });
      // ***** end best player *****
    },
    setStatisticsAtFinishGame: function (el) {
      this.statData.finishTime = _.now();
      this.statData.durationSec = Math.floor(
        (this.statData.finishTime -
          this.statData.startTime -
          this.statData.pauseTimeSum) /
          1000
      );
      this.statData.pairTries = el.pairsOpenend;
      this.statData.successRate = Math.round(
        (8 / this.statData.pairTries) * 100,
        2
      ); // 8 --> will flexible later
      this.statData.elementsOpened = el.squaresOpened;
      this.statData.commonClicksOnBoard = el.commonClicks;
    },
  },
  created: function () {
    this.statData = {
      playerName: "",
      startTime: 0,
      finishTime: 0,
      pauseStartTime: 0,
      pauseTimeSum: 0,
      durationSec: 0,
      pairTries: 0,
      elementsOpened: 0,
      commonClicksOnBoard: 0,
      successRate: 0,
    };
    eventBus.$on("startGameStatistics", () => {
      this.statData.playerName =
        "Henryk-" + Math.round(Math.random() * 1000, 0).toString();
      this.statData.startTime = _.now();

      console.log(`You name is ${this.statData.playerName}`);
    });
    eventBus.$on("finishGameStatistics", (el) => {
      this.setStatisticsAtFinishGame(el);
      this.saveAndUpdateStatistics(this.statData);
    });
    eventBus.$on("pauseGameStatistics", () => {
      if (this.statData.pauseStartTime === 0) {
        this.statData.pauseStartTime = _.now();
      } else {
        this.statData.pauseTimeSum =
          this.statData.pauseTimeSum + (_.now() - this.statData.pauseStartTime);
        this.statData.pauseStartTime = 0;
      }
    });
    eventBus.$on("showStat", () => {
      const pauseTimeSumSec = this.statData.pauseTimeSum / 1000;
      // debug: now; later it will delivver data to UI
      console.log("-------- STAT AREA --------");
      console.log(`
        Your Name: ${this.statData.playerName}. 
        You needed: ${this.statData.durationSec.toString()} seconds. 
        You took: ${pauseTimeSumSec.toString()} seconds break. 
        You clicked: ${this.statData.pairTries.toString()} times.
        And you opened: ${this.statData.elementsOpened.toString()} elements.
        You common clicks at the board were: ${this.statData.commonClicksOnBoard.toString()}.
        Your success rate is ${this.statData.successRate.toString()}%.

        -- Best Player --
        The less possible pairs openend: ${
          this.gameStatisticsRecords[this.bestPlayerIndicies.regardPairTries]
            .playerName
        } > ${
        this.gameStatisticsRecords[this.bestPlayerIndicies.regardPairTries]
          .pairTries
      }.
        Fastest: ${
          this.gameStatisticsRecords[this.bestPlayerIndicies.regardDuration]
            .playerName
        } > ${
        this.gameStatisticsRecords[this.bestPlayerIndicies.regardDuration]
          .durationSec
      } seconds.
        The most effective: ${
          this.gameStatisticsRecords[this.bestPlayerIndicies.regardEffectivity]
            .playerName
        } > ${
        this.gameStatisticsRecords[this.bestPlayerIndicies.regardEffectivity]
          .successRate
      }%.
      `);
      console.log("-------- end STAT AREA --------");
    });
  },
};
</script>

<!-- 
 
  -Presentation 
  vue2-bootstrap-modal
  https://vuejsexamples.com/bootstrap-modal-component-for-vue-2-x/
  https://www.npmjs.com/package/vue2-bootstrap-modal
 -->